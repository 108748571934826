.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.popup__title {
  margin: 17px auto 64px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #2F42C2;
}
.popup__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.1;
  max-width: 348px;
  text-align: center;
  color: #000000;
}
.popup__account {
  margin: 40px auto 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  color: #121759;
}
.popup__nextBtn {
  font-family: inherit;
  margin: 45px auto 40px;
  width: 260px;
  height: 57px;
  background: #5d3cc7;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 1400px) {
  .popup__title{
    font-size: 25px;
    margin: 12px auto 59px;
  }
  .popup__text {
    font-size: 18px;
    max-width: 68%;
  }
  .popup__account{
    margin: 16px auto 17px;
    font-size: 20px;
  }
  .popup__nextBtn {
    margin-top: 58px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }

}
@media screen and (max-width: 600px) {
  .popup__title{
    font-size: 24px;
    margin-bottom: 10px;
  }
  .popup__text {
    font-size: 15px;
  }
  .popup__nextBtn {
    margin-top: 20px;
    width: 200px;
    font-size: 14px;
  }
}
