.signin_private__form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto 82px;
}
.signin_private__label {
  margin-top: 23px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.8;
  color: #121759;
}
.signin_private__label:first-child {
  margin-top: 0;
}

.signin_private__input {
  box-sizing: border-box;
  padding: 0 20px;
  min-height: 41px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-family: inherit;
  font-size: 17px;
}
.signin_private__input::placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ababab;
}
.signin_private__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.signin_private__btn {
  font-family: inherit;
  width: 260px;
  height: 57px;
  margin: 32px auto 0;
  background-color: #006cc9;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.signin_private__btn_disabled {
  background-color: #cce2f4;
}
.signin_private__btn_disabled:hover {
  transform: none;
  opacity: 1;
}

.signin_private__select {
  width: 108px;
  min-width: unset;
  direction: initial;
  margin-right: 10px;
  padding-right: 15px;
}

.signin_private__phone_inputs {
  display: flex;
}
.signin_private__invalid_message {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #ff0000;
  text-align: center;
  margin: 27px auto 0;
}
.signin_private__questionsContainer{
  display: grid;
  justify-items: flex-start;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 18px;
}
.signin_private__note {
  margin: 0;
  max-width: 273px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #afa7a7;
}

.signin_private__note:first-of-type {
  margin-top: 32px;
}
.signin_private__strongContainer {
  margin-top: 16px;
  display: flex;
  gap: 4px;
}
.signin_private__strong {
  width: 39px;
  height: 5px;
  background-color: #dbd6ef;
}
.signin_private__strong_active {
  background-color: #eb7f00;
}
.signin_private__strongText {
  margin: 9px 0 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  text-align: right;
  color: #111111;
}
.signin_private__formField{
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1400px) {
  .signin_private__form {
    width: calc(100% - 90px);
    margin-bottom: 28px;
  }
  .signin_private__input {
    min-height: 27px;
    border-radius: 6px;
    padding: 0 5px;
    font-size: 13px;
  }
  .signin_private__input::placeholder {
    font-size: 12px;
  }
  .signin_private__label {
    font-size: 13px;
    margin-top: 18px;
    margin-bottom: 1px;
  }
  .signin_private__note:first-of-type {
    margin-top: 20px;
  }
  .signin_private__note {
    font-size: 13px;
  }
  .signin_private__strongText {
    margin-bottom: 17px;
  }
  .signin_private__invalid_message {
    font-size: 14px;
    line-height: 1;
    margin-top: 20px;
  }
  .signin_private__btn {
    font-size: 14px;
    margin-top: 10px;
    height: 37px;
    width: 195px;
  }
}
@media screen and (max-width: 900px) {
  .signin_private__questionsContainer{
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media screen and (max-width: 600px) {
  .signin_private__form {
    width: calc(100% - 80px);
    margin-bottom: 42px;
  }
  .signin_private__questionsContainer{
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .signin_private__input {
    min-height: 26px;
    font-size: 13px;
  }
  .signin_private__label {
    font-size: 12px;
    margin: 16px 0 0;
  }
  .signin_private__btn {
    margin-top: 16px;
    width: 150px;
    font-size: 14px;
    height: 36px;
  }
  .signin_private__invalid_message {
    font-size: 12px;
    margin-top: 12px;
  }
  .signin_private__note {
    font-size: 10px;
  }
  .signin_private__strongContainer {
    margin-top: 13px;
  }
  .signin_private__strong {
    width: 32px;
    height: 4px;
  }
  .signin_private__strongText {
    margin: 3px 0 5px;
    font-size: 10px;
  }
}
