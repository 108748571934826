.popupArticle {
  /* width: 77%; */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 6px 19px 19px;
}

.popup {
  width: 800px;
}

.popup__title {
  margin: 0 auto 3px;
  color: #2f42c2;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.popup__img {
  width: 145px;
  height: 145px;
  margin: 31px auto;
}

.popup__nextBtn {
  margin: 50px auto 27px;
  width: 260px;
}

.btn_full {
  background-color: #c4bae5;
  color: #5d3cc7;
  border-color: #5d3cc7;
  cursor: default;
}

@media screen and (max-width: 1500px) {
  .popupArticle {
    width: 97%;
    padding: 14px 14px 2px 14px;
  }

  .popup__title {
    margin: 8px auto 1px;
    font-size: 20px;
  }

  .popup__img {
    width: 113px;
    height: 113px;
    margin: 20px auto;
  }

  .popup__nextBtn {
    margin: 8px auto 5px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
}

@media screen and (max-width: 750px) {
  .popupArticle {
    width: 97%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  .popup__title {
    margin: 0 auto 33px;
  }

  .popup__img {
    width: 88px;
    height: 88px;
    margin: 13px auto;
  }

  .popup__nextBtn {
    width: 159px;
    height: 35px;
    font-size: 12px;
    margin-top: 11px;
  }
}



.section {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 14px auto;
  width: 75%;
  border-bottom: 1px solid #d1d1d1;
  position: fixed;
  background-color: #fff;
  z-index: 2;
}

.tab {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 52px;
  font-size: 18px;
  line-height: 19px;
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  color: #2f42c2;
}

.tab_disabled {
  opacity: 0.4;
}

.tabsImg {
  height: 28px;
}

.content {
  margin-top: 71px;
  max-height: 80vh;
  max-width: 91%;
  overflow: auto;
  padding: 0 0 20px 24px;
  box-sizing: border-box;
}

.content::-webkit-scrollbar {
  width: 5px;
  background: rgba(251, 242, 253, 0.62);
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  opacity: 0.4;
  background-color: #cce2f4;
}

.content::-webkit-scrollbar:horizontal {
  display: none;
}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
}

.inquiries {
  margin: 0;
  padding: 15px 0 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inquiry {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-areas: "number date name cycle content status";
  grid-template-columns: repeat(5, 18%);
  grid-auto-rows: 1fr;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: #00214e;
  font-size: 14px;
  position: relative;
}

.inquiry:last-of-type {
  border-radius: 0 0 30px 30px
}

.inquiry_colored {
  background-color: #e7f2fd;
}

.inquiryHeader {
  color: #2f42c2;
  font-size: 17px;
}

.inquiryCell {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inquiryNumber {
  grid-area: number;
}

.inquiryDate {
  grid-area: date;
}

.inquiryCategory {
  text-align: center;
}

.inquiryStatus {
  grid-area: status;
}

.inquiryName {
  grid-area: name;
}

.inquiryCycle {
  grid-area: cycle;
}

.inquiryRRM {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 0 auto;
}

.btn_take {
  font-size: 14px;
  height: 32px;
}

.home__section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  margin: 33px auto 7px;
  color: #2f42c2;
}

.home__sectionTitle {
  position: absolute;
  margin: 0;
  top: -20px;
  width: fit-content;
  height: 41px;
  background: #006cc9;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 18px;
  box-sizing: border-box;
}

.text {
  margin: 0;
  font-size: 18px;
  line-height: 1.1;
  padding: 26px 0 20px;
}

.notifications {
  display: flex;
  max-width: 100%;
  gap: 14px;
  box-sizing: border-box;
  padding: 29px 19px 23px;
}

.notifications__venchy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notifications__img {
  width: 56px;
  height: 56px;
}

.notifications__name {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.notifications__date {
  margin: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}

.notifications__text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  flex: 0 0 100%;
}

.nextBtn {
  width: 9px;
  height: 17px;
  position: absolute;
  top: 45%;
  left: -40px;
  background-image: url("../../../images/arrow_left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .home__section {
    margin-top: 40px;
    border-radius: 20px;
  }

  .inquiry:last-of-type {
    border-radius: 0 0 20px 20px
  }

  .home__sectionTitle {
    top: -17px;
    height: 35px;
    font-size: 16px;
    padding: 7px 15px;
  }

  .notifications {
    padding: 25px 19px 9px;
  }

  .notifications__text {
    font-size: 15px;
    line-height: 20px;
  }

  .text {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .tabs {
    width: 82%;
  }

  .tab {
    font-size: 15px;
  }

  .tabsImg {
    height: 24px;
  }
}

@media screen and (max-width: 900px) {
  .inquiry {
    grid-template-columns: repeat(5, 1fr);

  }
}

@media screen and (max-width: 800px) {
  .home__section {
    width: 99%;
    margin-right: 3px;
    box-shadow: 0 2px 21px rgba(0, 0, 0, 0.07);
    margin-top: 62px;
    margin-bottom: 13px;
  }

  .home__section:first-of-type {
    margin-top: 40px;
  }

  .text {
    padding-top: 34px;
  }
}

@media screen and (max-width: 700px) {
  .inquiry {
    gap: 8px;
    padding: 8px;
    font-size: 12px;
  }

  .btn_take {
    font-size: 12px;
  }

  .inquiryHeader {
    font-size: 14px;
  }

  .nextBtn {
    display: none;
  }

  .notifications {
    flex-direction: column;
    padding-top: 34px;
    gap: 8px;
  }

  .notifications__venchy {
    flex-direction: row;
    justify-content: center;
  }

  .notifications__img {
    width: 38px;
    height: 38px;
  }
}

@media screen and (max-width: 600px) {
  .tabs {
    width: 98%;
    height: 80px;
    top: 68px;
    margin: 0;
  }

  .tab {
    align-self: center;
    gap: 7px;
    height: 50px;
    font-size: 16px;
  }

  .tabsImg {
    height: 20px;
  }

  .content {
    max-height: unset;
    padding: 0 0 15px;
  }

  .inquiries {
    padding-top: 22px;
  }

  .inquiry {
    font-size: 13px;
    gap: 8px;
  }

  .inquiryHeader {
    font-size: 17px;
  }

}