.popupWindow {
  display: flex;
  margin: auto auto;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 21px -5px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  max-height: 94%;
  overflow-y: auto;
  /* width: 493px; */
  padding: 19px;
  direction: rtl;
  justify-content: center;

}

.popupWindow_type_wide {
  width: fit-content;
  max-width: 90vw;
}

.popupWindow::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.popupWindow::-webkit-scrollbar-track {
  /*background: transparent;*/
}

/* Handle */
.popupWindow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.popupWindow__closeBtn {
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../images/close_icon.svg");
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 10px 10px 0 auto;
}

@media screen and (max-width: 1800px) {
  .popupWindow {
    width: 385px;
    border-radius: 20px;
  }

  .popupWindow_type_wide {
    width: fit-content;
  }

  .popupWindow__closeBtn {
    position: absolute;
    top: 24px;
    right: 30px;
    width: 12px;
    height: 12px;
    margin: 4px 4px 0 14px;
    background-size: 12px 12px;
  }
}

@media screen and (max-width: 1400px) {
  .popupWindow {
    width: 385px;
    /* width: 97%; */
    border-radius: 20px;
  }

  .popupWindow_type_wide {
    width: fit-content;
  }

  .popupWindow__closeBtn {
    position: absolute;
    top: 24px;
    right: 30px;
    width: 12px;
    height: 12px;
    margin: 4px 4px 0 14px;
    background-size: 12px 12px;
  }
}

@media screen and (max-width: 1300px) {
  .popupWindow {
    width: 320px;
  }

  .popupWindow_type_wide {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .popupWindow {
    width: 301px;
    padding: 10px;
    border-radius: 16px;
  }

  .popupWindow_type_wide {
    width: fit-content;
  }
}