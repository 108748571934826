.myCalendar {
  width: calc(100% - 80px);
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.myCalendar__title {
  margin: 25px auto 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #2f42c2;
}

.calendarSection {
  margin: 40px auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 23px;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.68);
  border-radius: 32px;
  justify-items: center;
  gap: 30px;
  position: relative;
}

.tile {
  border-radius: 100%;
}

.circleContainer {
  display: flex;
  justify-content: center;
  gap: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #2f42c2;
}

.circle_big {
  width: 10px;
  height: 10px;
}

.appointmentsContainer {
  align-self: flex-start;

  width: 100%;
}

.appointmentsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 0 0;
  padding: 0;
  list-style-type: none;
  gap: 24px;
  width: 100%;
}

.appointmentsLi {
  box-sizing: border-box;
  padding: 24px 26px 24px 35px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  position: relative;
}

.appointmentsLi:after {
  display: block;
  cursor: pointer;
  content: "";
  width: 6px;
  height: 11px;
  background-image: url("../../images/arrow_left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 20px;
  top: 45%;
}

.time {
  display: flex;
  align-items: center;
}

.duration {
  margin: 0 14px 0 0;
  direction: ltr;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #626262;
}

.name {
  margin: 0 24px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.account {
  color: #79747e;
}

.noAppointmentsText {
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
}

.editAvailabilityBtn {
  position: absolute;
  top: -15px;
  right: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: #2f42c2;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}

.editAvailabilityBtn::before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-image: url("../../images/edit_icon_blue.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media screen and (max-width: 1300px) {
  .editAvailabilityBtn {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .myCalendar {
    width: calc(100% - 108px);
  }

  .myCalendar__title {
    margin: 11px auto 10px;
    font-size: 24px;
  }

  .calendarSection {
    gap: 20px;
    padding: 23px 23px 6px;
    margin: 20px auto;
  }

  .appointmentsContainer {
    align-self: flex-start;
    width: 100%;
  }

  .appointmentsList {
    gap: 15px;
  }

  .appointmentsLi {
    padding: 10px 20px 11px 28px;
  }

  .name {
    font-size: 15px;
    font-weight: 400;
  }

  .noAppointmentsText {
    font-size: 16px;
  }

  .duration {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .appointmentsLi {
    padding: 8px 14px 10px 24px;
  }

  .editAvailabilityBtn {
    font-size: 14px;
  }
}

@media screen and (max-width: 870px) {
  .myCalendar__title {
    margin: 10px auto 13px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .calendarSection {
    display: flex;
    flex-direction: column;
    padding: 28px 15px 18px;
    margin-top: 0;
  }

  .editAvailabilityBtn {
    top: 0;
    font-size: 13px;
  }

  .editAvailabilityBtn:before {
    height: 13px;
    width: 13px;
  }

  .myCalendar {
    width: 100%;
  }

  .appointmentsList {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .myCalendar {
    max-width: 358px;
  }

  .editAvailabilityBtn {
    font-size: 16px;
  }

}