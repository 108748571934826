.section {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 292px;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
}
.title {
  margin: 0 auto 67px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  color: #000000;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.8;
  color: #5d3cc7;
}
.input {
  box-sizing: border-box;
  padding: 8px 15px;
  background: #ffffff;
  border: 1px solid #5d3cc7;
  border-radius: 8px;
  font-family: inherit;
  font-size: 18px;
  width: 100%;
  margin-bottom: 33px;
}
.textarea {
  box-sizing: border-box;
  width: 100%;
  min-height: 214px;
  background: #ffffff;
  border: 1px solid #5d3cc7;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
  font-family: inherit;
  margin-bottom: 20px;
}
.btn {
  font-family: inherit;
  width: 100%;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.btn_disabled {
  background-color: #dbd4f4;
}
@media screen and (max-width: 1200px) {
  .section {
    padding-top: 9px;
  }
  .title {
    margin-bottom: 27px;
  }
  .label {
    font-size: 16px;
    line-height: 2;
  }
  .input {
    margin-bottom: 22px;
  }
  .textarea {
    min-height: 175px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding-top: 21px;
  }
  .title {
    font-size: 18px;
    margin-bottom: 14px;
  }
  .label {
    font-size: 16px;
    line-height: 2;
  }
  .input {
    margin-bottom: 0;
  }
  .textarea {
    min-height: 191px;
  }
}
