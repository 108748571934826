.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 96%;
  padding: 25px 0;
  box-sizing: border-box;
  max-width: 1300px;
  position: relative;
}

.title {
  margin: 0 auto 24px;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}

.text {
  margin: 0 2px 10px auto;
  color: #79747e;
  font-size: 16px;
  font-weight: 600;
}

.text_position_center {
  margin-left: auto;
  margin-right: auto;
}

.days {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}

.daysLi {
  display: grid;
  grid-template-rows: 35px 1fr;
  align-items: center;
  border: 1px solid #cee7ff;
  cursor: pointer;
}

.daysTitle {
  margin: 0;
  color: #00214e;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cee7ff;
}

.daysContent {
  width: 100%;
  height: 100%;
  min-height: 66px;
}

.daysTimeContainer {
  width: 100%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  direction: ltr;
}

.daysAvailableCircle {
  display: block;
  margin: 0 10px 0 10px;
  border-radius: 100%;
  background-color: #24a148;
  width: 8px;
  height: 8px;
}

.daysText {
  margin: 0;
  color: #111;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
}

.daysText_notAvailable {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #6f6f6f;
  background-color: rgba(0, 0, 0, 0.06);
}

.alternative {
  margin: 26px auto;
  font-weight: 600;
  color: #6f6f6f;
  display: flex;
  align-items: center;
}

.alternative:before {
  content: "";
  width: 7vw;
  height: 2px;
  background-color: #9e9e9e;
  margin: 3px 0 0 10px;
  min-width: 80px;
}

.alternative:after {
  content: "";
  width: 7vw;
  height: 2px;
  background-color: #9e9e9e;
  margin: 3px 10px 0 0;
  min-width: 80px;
}

.alternative__btn {
  max-width: 280px;
  height: 52px;
  min-height: 52px;
}

.calendarSection {
  background: rgba(255, 255, 255, 0.68);
  border-radius: 32px;
  padding: 20px;
}

.circleContainer {
  display: flex;
  justify-content: center;
  gap: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #24a148;
  margin-bottom: 2px;
}

@media screen and (max-width: 1300px) {
  .alternative__btn {
    max-width: 260px;
    height: 48px;
    min-height: 48px;
    font-size: 17px;
  }
}

@media screen and (max-width: 1200px) {
  .calendarSection {
    max-width: 297px;
  }

  .circle {
    width: 7px;
    height: 7px;
  }

  .daysText {
    font-size: 14.5px;
  }
}

@media screen and (max-width: 1024px) {
  .text {
    font-size: 15px;
  }

  .days {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  }

  .daysText {
    font-size: 15px;
  }

  .alternative {
    font-size: 17px;
  }

  .alternative__btn {
    font-size: 16px;
    height: 44px;
    min-height: 46px;
    width: 230px;
  }
}

@media screen and (max-width: 600px) {
  .days {
    grid-template-columns: 1fr 1fr;
  }
}