.carouselWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.carouselItems {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}
.carouselItem {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s ease;
}
.slider__sectionCard {
  margin: 0;
  padding: 19px 25px 20px 33px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 157px;
}
.slider__sectionContent {
  margin-right: 25px;
}
.slider__sectionText {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: right;
  color: #2f42c2;
  max-width: 212px;
}
.slider__sectionText_centered {
  text-align: center;
  margin-bottom: 10px;
}
.slider__sectionText_small {
  font-size: 14px;
  margin: 12px 0 0;
}

@media screen and (max-width: 1400px) {
  .slider__sectionCard {
    padding: 13px 17px 15px 25px;
    min-height: 135px;
  }
  .slider__sectionContent {
    margin-right: 25px;
  }
  .slider__sectionText {
    font-size: 16px;
    line-height: 21px;
  }
}
@media screen and (max-width: 800px) {
  .slider__sectionCard {
    padding: 23px 19px 15px 25px;
    min-height: 123px;
    gap: 17px;
    width: 100%;
  }
  .slider__sectionCard_centered {
    justify-content: center;
  }
  .slider__sectionText_small {
    margin: 12px auto 0;
  }
  .slider__sectionCard:last-of-type {
    padding-top: 15px;
  }
  .slider__sectionContent {
    margin-right: unset;
  }
  .slider__sectionText {
    font-size: 16px;
    line-height: 21px;
  }
  .slider__sectionText_small {
    margin: 12px auto 0;
  }
}
@media screen and (max-width: 700px) {
  .slider__sectionCard:first-child:after {
    width: 248px;
    height: 1px;
    bottom: 0;
    top: unset;
    left: 8%;
  }
}
