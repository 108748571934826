.popup {
  width: 819px;
  box-sizing: border-box;
  padding: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.39;
  text-align: right;
  color: #111111;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  direction: rtl;
}
.popup__title {
  margin: 71px 21px 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.1;
  color: #121759;
}
.prevencher {
  margin: 3px 10px 1px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.popup__img {
  width: 56px;
  height: 58px;
  object-fit: contain;
}
.prevencher__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;
  color: #323232;
}
.popup__text {
  width: calc(100% - 146px);
  margin: 8px auto 49px;
}
.buttonBar {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 119px;
  margin-bottom: 45px;
}
.popup__nextBtn {
  margin: 0;
  width: 260px;
  height: 57px;
  background: #5d3cc7;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.popup__nextBtn_white {
  background: #fff;
  border: 1px solid #5d3cc7;
  color: #5d3cc7;
}
@media screen and (max-width: 1300px) {
  .popup {
    width: 600px;
    padding: 10px;
    font-size: 16px;
  }
  .popup__title {
    margin-top: 27px;
    font-size: 18px;
  }
  .prevencher {
    gap: 8px;
  }
  .popup__img {
    width: 43px;
    height: 43px;
  }
  .prevencher__name {
    font-size: 13px;
  }
  .popup__text {
    width: calc(100% - 61px);
    font-size: 15px;
    margin: 5px auto 38px;
  }
  .buttonBar {
    gap: 90px;
    margin-bottom: 20px;
  }
  .popup__nextBtn {
    width: 190px;
    height: 42px;
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .popup{
    width: 300px;
  }
  .popup__title {
    font-size: 16px;
    margin: 0 auto 11px;
  }
  .prevencher {
    justify-content: center;
    margin: 0 auto 4px;
  }
  .prevencher__name {
    display: none;
  }
  .popup__text {
    font-size: 15px;
    margin: 0 14px 20px;
    width: fit-content;
  }
  .buttonBar {
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  .popup__img {
    width: 53px;
    height: 53px;
    margin: 0 auto;
  }
  .popup__nextBtn {
    width: 190px;
    height: 42px;
    font-size: 14px;
  }
}
