.section {
  margin: auto 0px;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 16px);
}

.availability {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 20px;
  height: 28px;
}

.label {
  font-size: 17px;
  line-height: 1.1;
  align-self: center;
}

.toggle {
  width: 56px;
  height: 0;
  cursor: pointer;
}

.toggle:before {
  box-sizing: border-box;
  display: block;
  content: "";
  width: 52px;
  height: 24px;
  border-radius: 100px;
  transition: all 0.3s;
}

.toggle_disabled:before {
  border: 1px solid #d3d3d3;
  background-image: url("../../../images/circle_grey.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 29px;
  background-size: 20px 20px;
}

.toggle_active:before {
  border-color: #e1e1e1;
  background: #dbd6ef url("../../../images/circle_purple.svg") no-repeat;
  background-position-y: center;
  background-position-x: 2px;
}

.timePickersList {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 8px;
}

.timePickerLi {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 37px;
  overflow: hidden;
}

.timePickerLi_open {
  height: fit-content;
}

.timeRange {
  display: grid;
  grid-template-columns: 1fr 50px 50px 50px;
  align-items: center;
  gap: 18px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  padding: 6px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 18px;
}

.timeRange:last-of-type {
  border: none;
}

.timeRange_disabled {
  background-color: #d3d3d3;
  color: #6f6f6f;
}

.timePicker {
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  justify-items: center;
  font-size: 16px;
  color: #00214e;
}

.timePicker_disabled {
  color: #6f6f6f;
}

.select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  justify-items: center;
  direction: ltr;
}

.timePickerText {
  margin: 0;
  justify-self: center;
  font-size: 16px;
}

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  width: 17px;
  height: 15px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../../../images/arrow_left_blue.svg");
}

.btn_type_delete {
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbd6ef;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: #2f42c2;
}

.btn_type_delete_disabled {
  background-color: #d3d3d3;
  color: #6f6f6f;
}

.incrementBtn {
  transform: rotate(90deg);
}

.decrementBtn {
  transform: rotate(-90deg);
}

.btn_type_add {
  width: fit-content;
  background-image: none;
  color: #2f42c2;
  font-size: inherit;
  margin-bottom: 20px;
}

.btn_type_submit {
  font-size: 20px;
  height: 48px;
}

.btn_type_submit:disabled {
  transform: translateY(-3px);
  opacity: 0.8;
  cursor: auto;
}

.plus {
  margin-left: 5px;
}

.title {
  margin: 35px 10px 10px 0px;
}

@media screen and (max-width: 1200px) {
  .section {
    width: 250px;
    max-width: unset;
    padding: 0;
  }

  .timeRange {
    width: 100%;
    font-size: 16px;
  }

  .btn_type_submit {
    font-size: 17px;
  }
}