.section {
  grid-template-columns: 1fr;
  grid-template-rows: 45px 40px 45px 1fr 150px;
  display: grid;
  width: 95%;
  height: 100vh;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
}
.header {
  width: 100%;
  height: 46px;
  display: grid;
  grid-template-columns: 1fr 100px 162px;
  column-gap: 24px;
  align-items: center;
  position: relative;
}
.title {
  font-size: 20px;
  color: #000;
  margin: 0 5px 0 0;
}
.btn {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  font-family: inherit;
  font-size: 16px;
  line-height: 1px;
  color: #6f6f6f;
  position: relative;
  cursor: pointer;
}
.inputContainer {
  position: relative;
}
.inputContainer:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 6px;
  top: 10px;
  background-image: url("../../images/search_icon_gray.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.input {
  width: 162px;
  height: 33px;
  padding-right: 30px;
  color: #6f6f6f;
  border-radius: 8px;
  border: 1px solid #6f6f6f;
  box-sizing: border-box;
}
.tableHeader {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  justify-items: center;
  align-items: center;
  border-radius: 12px 12px 0 0;
  background: rgba(93, 60, 199, 0.81);
}
.table {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  border: 1px solid #dbd6ef;
  overflow-y: auto;
}
.tableRow {
  box-sizing: border-box;
  height: 55px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 15px;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  border: 1px solid #dbd6ef;
  border-top: none;
  cursor: pointer;
}
.tableCell {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.totalList {
  list-style-type: none;
  padding: 0;
  margin: 9px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 150px;
  gap: 21px;
}
.noDocuments {
  margin: 14px;
}
.radio {
  accent-color: #5d3cc7cf;
}
.statusOptions {
  grid-column: 1/-1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #2c2b2b;
}
.dateFilterContainer input[type="text"] {
  border: 1px solid rgba(93, 60, 199, 0.81);
  margin-right: 1px;
}
.dateFilterContainer {
  display: flex;
  align-items: center;
  background: rgba(93, 60, 199, 0.81);
  border: 1px solid rgba(93, 60, 199, 0.81);
  border-radius: 5px;
}
.filterBtn {
  outline: none;
  border: none;
  color: #fff;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
}
.update {
  cursor: pointer;
  background-image: url("../../images/update_icon.svg");
  border: none;
  outline: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.update_disabled{
  background-image: url("../../images/update_disabled_icon.svg");
  cursor:  text;
}
.prescription__expiredTag {
  margin: 0;
  height: 35px;
  width: 100%;
  max-width: 160px;
  border-radius: 100px;
  border: 1px solid #24a148;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #24a148;
}
.prescription__expiredTag_expired {
  background: #9c9c9c;
  color: #fff;
  border: 1px solid #626262;
}
.prescription__expiredTag:before {
  position: absolute;
  right: 26px;
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  background: #24a148;
  border-radius: 100%;
}
.prescription__expiredTag_expired:before {
  background: #fff;
}
.addPrescriptionBtn{
  margin: 0 auto 0 0;
  height: 100%;
  width: fit-content;
  align-self: flex-end;
  background-color: initial;
  border: none;
  color: #1f8d3e;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1;
  outline: none;
}
.addPrescriptionBtn__plus{
  color: #24a148;
  font-size: 14px;
  margin-left: 4px;
}
@media screen and (max-width: 1300px) {
  .section {
    grid-template-rows: 40px 40px 40px 1fr 100px;
  }
  .header {
    height: 100%;
  }
  .title {
    font-size: 18px;
  }
  .input {
    height: 30px;
  }
  .tableHeader {
    font-size: 17px;
  }
  .tableRow {
    height: 50px;
    font-size: 14px;
  }
  .totalList {
    height: 90%;
    gap: 25px;
  }
  .totalCard {
    height: 100%;
    font-size: 40px;
    font-weight: 400;
  }
  .totalCard:after {
    top: 3px;
    right: 8px;
    font-size: 15px;
    font-weight: 400;
  }
  .statusOptions {
    font-size: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .documentTitle {
    font-size: 16px;
  }
  .documentLabel {
    font-size: 12px;
    margin: 3px 0;
  }
  .documentContent {
    font-size: 12px;
    line-height: 1.3;
  }
  .noDocuments {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    grid-template-rows: 32px 32px 32px 1fr 70px;
  }
  .header{
    grid-template-columns: 1fr 81px 162px;
    column-gap: 10px;
  }
  .title {
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
  }
  .tableHeader {
    font-size: 15px;
    font-weight: 400;
  }
  .tableRow {
    height: 38px;
    font-size: 13px;
  }
  .totalCard {
    font-size: 30px;
  }
  .totalCard:after {
    line-height: 1.2;
    font-size: 11px;
  }
  .document {
    width: 30%;
  }
  .prescription__expiredTag{
      height: 30px;
  }
  .prescription__expiredTag:before{
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .document {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .section {
    grid-template-rows: 30px 42px 42px 42px 1fr 70px;
  }
  .header {
    grid-template-columns: 1fr 1fr;
  }
  .title {
    display: none;
  }
  .title_mobile{
    display: flex;
    justify-content: center;
  }
  .btn {
    font-size: 16px;
    width: 85px;
  }
  .input {
    font-size: 16px;
  }
  .document {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
  }
  .tableCell_hide {
    display: none;
  }
  .tableHeader {
    grid-template-columns: repeat(3, 1fr);
    font-size: 15px;
    font-weight: 400;
  }
  .tableRow {
    grid-template-columns: repeat(3, 1fr);
    font-size: 13px;
  }
}
