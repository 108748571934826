.popup {
  width: 493px;
  background-color: #fff;
  box-sizing: border-box;
  /* padding: 19px; */
}

.popup__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ecf2ff;
  border-radius: 16px;
  width: 100%;
  height: 100%;

}

.popup__title {
  margin: 18px auto 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #006cc9;
}

.popup__form {
  display: flex;
  flex-direction: column;
  width: 324px;
  align-items: inherit;
}

.popup__link_label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #626262;
  width: 100%;
  text-align: right;
  margin-bottom: 6px;
}

.popup__link_input {
  box-sizing: border-box;
  padding: 8px 20px;
  text-align: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  height: 41px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
}

.popup__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}

.popup__input::placeholder {
  color: #d4d4d4;
}

.popup__label {
  padding: 8px 16px;
  background-color: #5d3cc7;
  /* Purple */
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0px 9px;
  width: 100%;
  display: flex;
  height: 36px;
  align-items: center;
}

.popup__label:hover {
  background-color: #553c9a;
  /* Darker Purple */
}

.popup__label:focus {
  outline: 2px solid #805ad5;
  /* Focus Ring */
}

.popup__input_hiden {
  display: none;
}

.popup__img {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 20px;
}

.popup__img_upload {
  max-width: 15px;
  max-height: 40px;
  margin: 0px 5px 0px 4px;
  filter: invert(1) brightness(1000%) contrast(90%);
}

.file_input_wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 9px;
}

.popup__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}

.popup__input::placeholder {
  color: #d4d4d4;
}

.popup__invalid_message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
  margin: 20px auto 0;
}

.popup__submitBtn {
  margin: 30px auto 30px;
  width: 260px;
  font-weight: 600;
  font-size: 18px;
}

.popup__submitBtn_disabled {
  background-color: #cce2f4;
}

@media screen and (max-width: 1400px) {
  .popup {
    width: 418px;
  }

  .popup__title {
    margin: 17px auto 22px;
    font-size: 20px;
  }

  .popup__form {
    width: 275px;
  }

  .popup__label {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .popup__input {
    padding: 6px 17px;
    border-radius: 7px;
    height: 35px;
    margin-bottom: 34px;
    font-size: 15px;
  }

  .popup__submitBtn {
    margin: 32px auto 7px;
    width: 220px;
    height: 48px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .popup {
    width: 301px;
  }

  .popup__title {
    font-size: 19px;
  }

  .popup__form {
    width: calc(100% - 30px);
  }

  .popup__submitBtn {
    width: 200px;
    font-size: 16px;
  }

  .popup__invalid_message {
    font-size: 12px;
    margin-top: 12px;
  }
}