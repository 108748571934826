.section {
  padding: 20px 0;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.editOptions {
  width: 75%;
  margin: 20px auto 0;
  padding: 0;
  list-style-type: none;
  row-gap: 14px;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  justify-content: space-around;
}

.emptyOption {
  display: flex;
  padding: 14px 41px;
  margin: 0 0 10% 10%;
  height: 10%;
}

.editOption {
  text-decoration: none;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 41px;
  color: #2f42c2;
  position: relative;
  margin: 0 0 10% 10%;
}

.editOptionColor{
  background-color: rgb(241, 243, 145);
}

.editOptionLeft {
  text-align: left;
  padding: 0;
  width: 50%;
}

.editOptionRight {
  text-align: right;
  padding: 0;
  width: 50%;
}


.editOption:after {
  content: "";
  position: absolute;
  top: 40%;
  left: 20px;
  height: 16px;
  width: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url("../../images/arrow_left_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.editOptionComment {
  margin-right: 5px;
  font: inherit;
  color: #626262;
  font-size: 16px;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  gap: 12px;
}

.table__header {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid #6f6f6f;
  justify-items: center;
}

.table__columns {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.sources {
  gap: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
}

.centeredFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  margin: 2px auto;
  background-color: #0044c9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  height: 24px;
}

.btn:first-child {
  margin-top: 4px;
}

.btn_white {
  color: #0044c9;
  border: 1px solid #0044c9;
  background-color: #fff;
}

.btn_chat {
  background-color: #24a148;
}

.btn_disabled {
  opacity: 0.5;
  cursor: default;
}

.header {
  background-color: #a9d4ff;
  width: 100%;
  padding: 20px;
  margin: 0;
  color: #2f42c2;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 20px;
  gap: 10px;
}

.headerContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 30% 20%;
  gap: 2px 8px;
}

.headerText {
  margin: 0;
  color: #00214e;
  font-size: 18px;
}

.headerTitle {
  margin: 0;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.headerRrm {
  grid-row: span 2;
}

.select {
  width: 90%;
  font-size: 16px;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content>.header,
.content>div:first-child,
.content>textarea {
  grid-column: 1 / -1;
}

.textarea {
  box-sizing: border-box;
  padding: 3px 6px;
  font-size: 16px;
}

.textarea::placeholder {
  font-size: 16px;
}

.reports_table {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-start;
  /* Align tables from the left side */
  width: 100%;
}

.reports_table>right_table {
  margin-right: auto;
}

.reports_table>left_table {
  margin-left: auto;
}

@media screen and (max-width: 1300px) {
  .header {
    padding: 6px 12px;
    font-size: 16px;
    gap: 6px;
  }

  .headerText {
    font-size: 14px;
  }

  .headerTitle {
    font-size: 16px;
  }

  .select {
    font-size: 13px;
  }

  .editOptions {
    row-gap: 10px;
  }

  .emptyOption {
    padding: 14px 22px;
    height: 14%;

  }

  .editOption {
    padding: 14px 22px;
  }

  .editOption:after {
    left: 15px;
    height: 14px;
    width: 7px;
  }

  .textarea::placeholder,
  .textarea {
    font-size: 14px;
  }

  .table__columns {
    gap: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    margin: 0 auto 8px;
  }

  .editOptions {
    width: 80%;
    font-size: 16px;
    column-gap: 5%;
  }

  .emptyOption {
    padding: 12px 22px;
  }

  .editOption {
    padding: 12px 22px;
  }

  .editOptionComment {
    font-size: 14px;
  }

  .select {
    font-size: 12px;
  }

}

@media screen and (max-width: 950px) {
  .table__header {
    grid-template-rows: 1fr;
  }

  .header {
    font-size: 16px;
  }

  .headerContainer {
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
  }

  .title {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .table__columns {
    width: 95%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    gap: 5px;
  }

  .centeredFlex {
    align-items: flex-start;
    width: fit-content;
  }

  .btn_chat {
    margin-right: calc(50% + 4px);
  }

}

@media screen and (max-width: 850px) and (min-width: 600px) {
  .table__columns {
    gap: 4px;
  }
}

@media screen and (max-width: 700px) {
  .section {
    width: calc(100% - 18px);
  }

  .headerContainer {
    grid-template-columns: 1fr;
    justify-items: flex-start;
  }

  .title {
    font-size: 18px;
  }

  .editOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    margin-bottom: 30px;
    align-content: flex-start;
  }

  .emptyOption {
    width: 270px;
  }

  .editOption {
    width: 270px;
  }

  .btn {
    width: 80px;
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    grid-template-columns: 1fr;
  }
}