.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 52px 0;
  direction: rtl;
}
.title {
  margin: 0 6px;
  font-size: 36px;
  font-weight: 700;
  color: #2f42c2;
}
.text {
  margin: 0 0 3px;
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  max-width: 729px;
  line-height: 1.2;
}
.text_small {
  font-size: 20px;
  margin: 18px 0 50px;
}
.img {
  width: 300px;
  height: 278px;
  margin: 46px auto;
}
.link {
  margin-bottom: 28px;
  width: 260px;
}
.footer {
  margin: 16px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.67);
  padding: 28px 40px;
}
.footerTitle {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}
.footerText {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .title {
    margin: 0 10px;
    font-size: 28px;
    font-weight: 600;
  }
  .text {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 600;
    max-width: 60%;
    line-height: 1.2;
  }
  .text_small {
    font-size: 12px;
    margin: 18px 30px;
  }
  .img {
    width: 250px;
    height: 240px;
    margin: 26px auto;
  }
  .link {
    width: 220px;
    height: 45px;
    font-size: 16px;
  }

}
@media screen and (max-width: 800px) {
  .section {
    width: calc(100% - 50px);
  }
  .title {
    font-size: 24px;
  }
  .text {
    font-size: 14px;
     max-width: unset;
  }
}
