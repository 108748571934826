.answer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.39;
  text-align: right;
  color: #111111;
  box-sizing: border-box;
  padding: 29px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.answer__title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.1;
  text-align: right;
  color: #121759;
}
.prevencher {
  margin: 6px 0 1px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.prevencher__avatar {
  width: 56px;
  height: 58px;
  object-fit: contain;
}
.prevencher__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;
  color: #323232;
}
.answer__text {
  margin: 10px 0 0;
}

@media screen and (max-width: 1300px) {
  .answer {
    font-size: 16px;
    line-height: 1.1;
    padding: 14px 20px 20px;
  }
  .answer__title {
    font-size: 18px;
  }
  .answer__text {
    margin: 0 28px;
  }
  .prevencher {
    margin-top: 4px;
  }
  .prevencher__avatar{
    width: 50px;
    height: 50px;
  }
  .prevencher__name{
    font-size: 15px;
  }
}
@media screen and (max-width: 700px) {
  .answer {
    font-size: 15px;
  }
  .answer__title {
    color: #5d3cc7;
    font-size: 16px;
  }
  .answer__text {
    margin: 0;
  }
  .prevencher {
    margin: 0;
  }
  .prevencher__avatar {
    width: 35px;
    height: 35px;
  }
  .prevencher__name {
    font-size: 15px;
    margin: 15px 0 10px;
  }
}
