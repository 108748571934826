.section {
  margin: 0 auto;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.editOptions {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 304px);
  column-gap: 10%;
  row-gap: 14px;
}
.editOption {
  text-decoration: none;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 41px;
  color: #2f42c2;
  position: relative;
}
.optionDisable{
  opacity: 0.5;
}
.editOptionBtn {
  border: none;
  font-size: 19px;
}
.editOption:after {
  content: "";
  position: absolute;
  top: 40%;
  left: 20px;
  height: 16px;
  width: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url("../../images/arrow_left_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1300px) {
  .editOptions {
    width: 75%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }
  .editOption {
    padding: 14px 22px;
    font-size: 18px;
  }
  .editOption:after {
    left: 15px;
    height: 14px;
    width: 7px;
  }
}
@media screen and (max-width: 1024px) {
  .editOptions {
    width: 80%;
  }
  .editOption {
    padding: 12px 16px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .editOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    margin-bottom: 30px;
  }
  .editOption {
    width: 270px;
  }
  .btn{
    width: 270px;
    font-size: 18px;
  }
}
