.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: ltr;
}
.popup__title {
  margin: 14px auto 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #006cc9;
}
.popup__form {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 324px;
}
.popup__label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #626262;
  width: 100%;
  text-align: right;
  margin-bottom: 6px;
}
.popup__input {
  box-sizing: border-box;
  padding: 8px 20px;
  text-align: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  height: 41px;
  margin-bottom: 38px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
}
.popup__input::placeholder {
  color: #d4d4d4;
}
.popup__list {
  display: flex;
  list-style-type: none;
  direction: rtl;
  padding: 0;
  margin: 0;
}
.popup__li {
  width: 50px;
  height: 6px;
  background: #d9d9d9;
  margin: 0 0 0 5px;
}
.popup__li_active {
  background: #9e9e9e;
}
.popup__strength {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #626262;
  margin: 12px 0 7px;
}
.popup__text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  color: #afa7a7;
  margin: 13px 0;
}
.popup__submitBtn {
  margin: 38px auto 30px;
  width: 260px;
  height: 57px;
  background: #006cc9;
  border: 1px solid #e1e1e1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.0588235);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  text-align: center;
  color: #ffffff;
}
.popup__submitBtn_disabled {
  background-color: #cce2f4;
}
.popup__invalid_message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
  margin: 20px auto 0;
}

@media screen and (max-width: 1400px) {
  .popup__title {
    margin: 7px auto 22px;
    font-size: 20px;
  }
  .popup__form {
    width: 275px;
  }
  .popup__label {
    font-size: 15px;
    margin-bottom: 6px;
  }
  .popup__input {
    padding: 6px 17px;
    border-radius: 7px;
    height: 35px;
    margin-bottom: 34px;
    font-size: 17px;
  }
  .popup__strength {
    font-size: 14px;
    margin: 10px 0 7px;
  }
  .popup__text {
    font-size: 14px;
    margin: 10px 0;
  }
  .popup__submitBtn {
    margin: 32px auto 7px;
    width: 220px;
    height: 48px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .popup__title {
    font-size: 19px;
  }
  .popup__form {
    width: calc(100% - 30px);
  }
  .popup__text {
    font-size: 14px;
  }
  .popup__submitBtn {
    width: 200px;
    font-size: 16px;
  }
}
