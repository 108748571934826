.section {
  width: 96%;
  position: relative;
  direction: rtl;
  padding: 50px 0 20px;
  box-sizing: border-box;
}
.content {
  width: 95%;
  margin: 0 auto;
  max-width: 910px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.input {
  min-width: 280px;
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 90px;
  background-image: url("../../images/search_icon.svg");
  background-repeat: no-repeat;
  background-position: bottom 12px right 27px;
  box-sizing: border-box;
  padding: 0 45px 0 10px;
  font-family: inherit;
  font-size: 20px;
}
.themes {
  margin: 24px 0 33px;
  display: flex;
  justify-content: center;
  gap: 32px;
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  overflow-x: scroll;
  width: 100%;
  min-height: 50px;
  padding: 0 0 8px;
}
.themes::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
/* Handle */
.themes::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.themesLi {
  cursor: pointer;
  background: #8a74d8;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 152px;
  height: 46px;
}
.themeLi_active {
  background-color: #121759;
}
.btn {
  align-self: flex-end;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1;
  color: #121759;
}
.plus {
  color: #33a3ff;
  font-size: 14px;
  line-height: 1;
  margin-left: 9px;
}
.forumList {
  width: 98%;
  list-style-type: none;
  padding: 0 0 0 10px;
  margin: 25px 0 0;
  display: flex;
  flex-direction: column;
  gap: 53px;
  overflow: scroll;
}
.forumList::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
/* Handle */
.forumList::-webkit-scrollbar-thumb {
  border-radius: 5px;
  opacity: 0.4;
  background: rgba(251, 242, 253, 0.62);
}
.forumList::-webkit-scrollbar:horizontal {
  display: none;
}
/* Handle on hover */
.forumList::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  opacity: 0.4;
  background: #2f42c2;
}

.forumLi {
  cursor: pointer;
  width: 100%;
  background: #fff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 14px;
  padding: 24px 23px 17px 54px;
  box-sizing: border-box;
}
.card__title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: right;
  color: #5d3cc7;
}
.card__user {
  margin: 20px 0 9px;
  display: flex;
  gap: 8px;
}
.card__avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  object-fit: cover;
}
.card__data {
  display: flex;
  flex-direction: column;
}
.card__name {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2;
  text-align: right;
  color: #323232;
}
.card__date {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  text-align: right;
  color: #848484;
}
.text {
  margin: 0 40px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  text-align: right;
  color: #7a757d;
}
.messages {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 23px 0 0;
  width: 100%;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: #121759;
}
.messagesIcon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-left: 9px;
}
@media screen and (max-width: 1200px) {
  .title {
    margin: 34px auto 36px;
    font-size: 32px;
    line-height: 42px;
  }
  .input {
    min-width: 236px;
    min-height: 33px;
    background-position: bottom 8px right 27px;
    font-size: 16px;
  }
  .themes {
    margin: 20px 0 16px;
    font-size: 16px;
    padding: 0 0 6px;
  }
  .themesLi {
    min-width: 117px;
    height: 39px;
  }
  .btn {
    font-size: 14px;
  }
  .plus {
    font-size: 13px;
  }
  .forumList {
    margin: 21px 0 0;
    gap: 44px;
  }
  .forumLi {
    padding: 21px 24px 15px 30px;
  }
  .card__title {
    font-size: 17px;
    line-height: 18px;
  }
  .card__user {
    margin: 17px 0 7px;
    gap: 7px;
  }
  .card__avatar {
    width: 30px;
    height: 30px;
  }
  .card__name {
    font-size: 13px;
  }
  .card__date {
    font-size: 11px;
    line-height: 1.4;
  }
  .text {
    font-size: 15px;
  }
  .messages {
    margin: 20px 0 0;
    font-size: 12px;
  }
  .messagesIcon {
    width: 13px;
    height: 13px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: calc(100% - 28px);
  }
  .sidebar {
    display: none;
  }
  .title {
    margin: 0 auto 27px;
    font-size: 24px;
    line-height: 31px;
  }
  .themes {
    margin: 24px 0;
    gap: 15px;
    font-size: 15px;
  }
  .themesLi {
    min-width: 112px;
    height: 38px;
  }
  .forumList {
    padding: 0;
  }
  .forumLi {
    padding: 15px 11px 8px 38px;
    position: relative;
    border-radius: 7px;
  }
  .forumLi:after {
    position: absolute;
    top: 48%;
    left: 17px;
    content: "";
    background-image: url("../../images/arrow_left_blue.svg");
    width: 6px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .card__user {
    margin-top: 13px;
  }
}
