.pagination{
    display: flex;
    width: 100%;
    gap: 15px;
    justify-content: center;
    align-items: baseline;
    font-size: 16px;
    color: #626262;
    line-height: 1;
}
.btn{
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 12px;
}
.btn_backward{
    background-image: url(../../images/arrow_left_gray.svg);
}
.btn_forward{
    background-image: url(../../images/arrow_left_gray.svg);
    rotate: 180deg;
}