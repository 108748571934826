.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.popup__title {
  margin: 17px auto 50px;
  color: #2f42c2;
  font-size: 32px;
  font-weight: 700;
}
.popup__img {
  width: 145px;
  height: 145px;
}
.popup__text {
  margin: 0 auto 87px;
  max-width: 270px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-family: Assistant,  sans-serif;
  font-weight: 600;
  line-height: 24px;
}
.popup__nextBtn {
  margin: 35px auto 8px;
  width: 260px;
  height: 57px;
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .popup__title {
    margin: 13px auto 46px;
    font-size: 24px;
  }
  .popup__img {
    width: 113px;
    height: 113px;
  }
  .popup__text {
    margin: 0 auto 67px;
    max-width: 270px;
    font-size: 18px;
    line-height: 1.1;
  }
  .popup__nextBtn {
    margin: 27px auto 5px;
    width: 203px;
    height: 44px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .popup__title {
    margin: 10px auto 38px;
    font-size: 20px;
  }
  .popup__text {
    font-size: 15px;
    max-width: 170px;
    margin-bottom: 50px;
  }
  .popup__img {
    width: 88px;
    height: 87px;
  }
  .popup__nextBtn {
    width: 159px;
    height: 35px;
    font-size: 12px;
    margin-top: 22px;
  }
}
