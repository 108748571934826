.document {
  position: fixed;
  width: 25%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 7px -1px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 3;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
  display: block;
  padding: 0 28px 10px 10px;
  overflow-y: auto;
}

.document_visible {
  transform: translateX(0);
}

.documentContainer {
  width: 100%;
}

.document::-webkit-scrollbar {
  width: 6px;
  background-color: #cee7ff;
}

/* Handle */
.document::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.noDocuments {
  font-size: 15px;
  margin: 0 0 6px;
}

.documentCloseBtn {
  margin: 14px 0 0 auto;
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  border: none;
  background-image: url("../../images/close_icon.svg");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  align-self: flex-start;
}

.chatBtn {
  border-color: rgb(24 124 17 / 81%);
    color: white;
    background-color: rgb(24 124 17 / 81%);
}

.documentTitle {
  margin: 0 auto 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.documentSubTitle {
  margin: 10px auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.documentInfo {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 1.2fr 1.5fr;
}

.documentLinks {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}

.documentLabel {
  margin: 7px 0;
  font-size: 16px;
  align-self: flex-start;
  color: #2c2b2b;
  display: flex;
  gap: 5px;
  align-items: center;
}


.documentStatusLabel {
  font-size: 16px;
  color: #2c2b2b;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin: 14px 0;
}

.documentText {
  color: #000;
  display: inline-block;
  margin: 0;
  text-decoration: underline;
}

.documentLink {
  color: #da1e28;
  cursor: pointer;
  font-weight: 600;
}

.documentAttached {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("../../images/pdf_icon.svg");
  background-repeat: no-repeat;
}

.documentContent {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  padding: 14px 12px;
  box-sizing: border-box;
  font-size: 16px;
  margin: 0;  
  display: block;
}

.documentBtn {
  margin: auto 0;
}

.documentUpdates {
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px 0;
  width: 100%;
}

.documentTextArea {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  min-height: 40px;
  padding: 5px 10px 5px 33px;
}

.textAreaContainer {
  width: 100%;
  position: relative;
}

.updateInfo {
  width: 100%;
  display: flex;
  gap: 20px;
}

.sendUpdate {
  outline: none;
  border: none;
  background-color: transparent;
  height: 22px;
  width: 22px;
  background-image: url("../../images/chat_send_icon.svg");
  position: absolute;
  left: 10px;
  top: 10px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn {
  margin: auto auto 10px;
}

.btn_disabled {
  background-color: #e1e1e1;
  color: #6f6f6f;
  border-color: #6f6f6f;
}

.btn_red {
  background-color: #e0b8b8;
  color: #da1e28;
  border-color: #da1e28;
  position: relative;
  height: 47px;
  margin-top: 20px;
}

.btn_full {
  background-color: #c4bae5;
  color: #5d3cc7;
  border-color: #5d3cc7;
  height: 47px;
}

.btn_type_actionReport {
  margin: 4px auto;
  max-height: 32px;
  /*max-width: fit-content;*/
  /*padding: 0 18px;*/
  /*font-size: 16px;*/
  border-radius: 12px;
}

@media screen and (max-width: 2500px) {
  .document {
    padding: 0 15px;
    width: 22%;
  }

  .btn_type_actionReport {
    font-size: 16px;
  }

  .btn {
    height: 40px;
    font-size: 16px;
  }

  .documentLabel {
    font-size: 14.5px;
  }

  .documentStatusLabel {
    font-size: 14.5px;
  }
}

@media screen and (max-width: 1300px) {
  .document {
    padding: 0 25px;
  }

  .documentCloseBtn {
    width: 14px;
    height: 14px;
  }

  .documentTitle {
    margin: 0 auto 14px;
    font-size: 18px;
  }

  .documentSubTitle {
    font-size: 16px;
    line-height: 1;
  }

  .documentLabel {
    font-size: 14px;
  }

  .documentStatusLabel {
    font-size: 14px;
  }

  .documentContent {
    border-radius: 6px;
    padding: 8px;
    font-size: 13px;
  }

  .documentBtn {
    height: 42px;
    width: 90%;
    font-size: 18px;
  }

  .btn {
    height: 40px;
    font-size: 16px;
  }

  .btn_type_actionReport {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .documentCloseBtn {
    width: 10px;
    height: 10px;
  }

  .documentTitle {
    font-size: 16px;
  }

  .documentSubTitle {
    font-size: 14px;
    margin: 6px auto;
  }

  .documentLabel {
    font-size: 12px;
    margin: 5px 0;
  }

  .documentStatusLabel {
    font-size: 12px;
  }

  .documentContent {
    font-size: 12px;
    line-height: 1.3;
  }

  .documentTextArea {
    font-size: 12px;
    line-height: 1.3;
  }
}

@media screen and (max-width: 1024px) {
  .document {
    width: 30%;
  }

  .btn_type_actionReport {
    height: 25px;
    font-size: 13px;
  }
}

@media screen and (max-width: 750px) {
  .document {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .document {
    margin: 65px 10px 0 15px;
    width: 90%;
    box-sizing: border-box;
    z-index: 2;
  }
}