.list {
  list-style-type: none;
  flex: 1;
  max-width: 50%;
    margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 1.1;
  max-height: 49vh;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: right;
}

.right_table   {
  margin-left: auto;
  order: 1;
}

.left_table {
  margin-right: auto;
  order: 2;
}

.li {
  gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
}

.li_header {
  color: #2f42c2;
  font-weight: 600;
}

.li:nth-child(even) {
  background-color: rgba(132, 196, 253, 0.4);
}

.text {
  margin: 0;
}

.noResults {
  margin-right: 15px;
}

@media screen and (max-width: 1300px) {
  .list {
    font-size: 14px;
    max-height: calc(100vh - 300px);
  }
}

@media screen and (max-width: 800px) {
  .list {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .list {
    max-height: unset;
    border-top: 1px solid #9c9c9c;
    ;
  }
}