.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../images/login_therapists_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  direction: rtl;
}
.signin__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 493px;
  width: 100%;
  min-height: 780px;
  background-color: #fff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  border-radius: 26px;
  z-index: 2;
}
.signin__arrow {
  margin: 26px 26px 0 auto;
  cursor: pointer;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url('../../images/arrow_forward.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.signin__title {
  margin: 0 auto;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.3;
  color: #006cc9;
}

.signin__text {
  margin: 20px auto 75px;
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  max-width: 389px;
}
.signin__text_small{
  font-size: 18px;
  line-height: 1.3;
}

@media screen and (max-width: 1400px) {
  .signin__content {
    margin-top: 0;
    max-width: 300px;
    min-height: unset;
    border-radius: 15px;
  }
  .signin__arrow {
    margin: 17px 17px 0 auto;
    width: 17px;
    height: 17px;
  }
  .signin__title {
    font-size: 27px;
  }
  .signin__text {
    margin: 9px auto 42px;
    font-size: 17px;
    max-width: calc(100% - 24px);
  }
  .signin__text_small{
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .signin__content {
    width: 284px;
    height: unset;
    padding-bottom: 15px;
  }
  .signin__arrow{
    display: none;
  }
  .signin__title{
    font-size: 24px;
    line-height: 31px;
    margin: 32px auto 15px;
  }
  .signin__text {
    margin: 0 auto 42px;
    font-size: 16px;
  }
  .signin__text_small{
    font-size: 12px;
    max-width: 252px;
  }
}
