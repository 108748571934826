.btn{
    font-size: 16px;
    line-height: 1;
    height: fit-content;
    font-weight: 400;
    margin: 0;
}
@media screen and (max-width: 1300px) {
.btn{
    font-size: 14px;
}
}