.section {
  width: 98%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
}
.title {
  margin: 0 auto 24px;
  font-size: 24px;
  font-weight: 600;
}
.form {
  box-sizing: border-box;
  padding: 20px;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  gap: 20px;
}
.form__subtitle {
  color: #626262;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.77;
  margin: 0 0 5px;
}
.form__field{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 95px;
  gap: 10px;
}
.form__input {
  width: 100%;
  box-sizing: border-box;
  text-align: right;
  color: #000;
  padding: 10px 17px;
  background: #fff;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}
.form__input:focus, .form__input:focus-visible {
  outline: #2684ff;
  border: 2px solid #2684ff;
}
.form__textarea {
  height: 36px;
  width: 100%;
}
.marginRight {
  margin-right: 5px;
}
.submitBtn {
  width: 250px;
  height: 48px;
  min-height: 48px;
  margin: 10px;
}

@media screen and (max-width: 1200px) {
  .form__subtitle,
  .form {
    font-size: 14px;
  }
  .submitBtn {
    width: 200px;
    height: 42px;
    min-height: 42px;
    margin: 6px;
    font-size: 17px;
  }
  .form__field{
    grid-template-columns: 1fr 80px;
  }

}

@media screen and (max-width: 850px) and (min-width: 600px) {
  .title {
    margin-bottom: 20px;
    font-size: 22px;
  }
  .form {
    padding: 14px 0;
    grid-template-columns: 1fr 1fr;
    font-size: 13px;
  }
  .form__subtitle {
    font-size: 13px;
    line-height: 1.2;
  }
  .form__input {
    width: 170px;
    padding: 5px 10px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding: 20px 20px 0;
  }
  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .form {
    grid-template-columns: 1fr;
  }
  .form__input {
    width: 100%;
  }
}
