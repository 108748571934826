.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  margin: 0 auto 50px;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.1;
  text-align: right;
  color: #000000;
}
.list {
  width: 100%;
  height: 100%;
  margin: 0 auto 50px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

@media screen and (max-width: 1200px) {
  .title {
    margin: 20px auto 40px;
    font-size: 18px;
  }
  .list {
    margin: 0 auto 40px;
    gap: 25px;
  }
}

@media screen and (max-width: 600px) {
  .section {
    width: 92%;
  }
  .title {
    margin: 0 auto 30px;
    font-size: 16px;
  }
  .list {
    margin: 0 auto 20px;
    gap: 15px;
  }
}
