.popup {
  box-sizing: border-box;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__title {
  margin: 14px auto 59px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  color: #006cc9;
}
.popup__text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  margin: 0 auto 87px;
  max-width: 269px;
}
.popup__img {
  width: 145px;
  height: 145px;
}
.popup__btn {
  margin: 35px auto 8px;
  width: 260px;
}

@media screen and (max-width: 1400px) {
  .popup__title {
    margin: 13px auto 46px;
    font-size: 25px;
  }
  .popup__text {
    font-size: 19px;
    margin-bottom: 67px;
    max-width: 210px;
  }
  .popup__img {
    width: 112px;
    height: 112px;
  }
  .popup__btn {
    margin-top: 27px;
    width: 203px;
    height: 45px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .popup__title {
    font-size: 19px;
  }
  .popup__text {
    font-size: 14px;
  }
  .popup__btn {
    width: 200px;
    font-size: 16px;
  }
}
