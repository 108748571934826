.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #111111;
}
.popup__title {
  margin: 0 auto 33px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #2f42c2;
}
.popup__text {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
}
.popup__text_big {
  margin: 0 auto 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
}
.popup__btn {
  margin: 0 auto 12px;
  width: 260px;
  height: 57px;
  border-radius: 10px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  cursor: pointer;
}
.popup__nextBtn {
  color: #5d3cc7;
  border: 1px solid #5d3cc7;
  background: transparent;
}
@media screen and (max-width: 1200px) {
  .popup__title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .popup__text {
    font-size: 14px;
  }
  .popup__text_big {
    margin: 0 auto 30px;
    font-size: 16px;
  }
  .popup__text_big:last-of-type {
    margin-bottom: 15px;
  }
  .popup__nextBtn {
    font-size: 16px;
  }
  .popup__btn {
    width: 220px;
    height: 47px;
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .popup__title {
    font-size: 19px;
    margin-bottom: 20px;
  }
  .popup__btn {
    height: 50px;
    width: 190px;
    font-size: 16px;
  }
  .popup__nextBtn {
    margin-top: 21px;
  }
}
