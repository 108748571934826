.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    place-items: center;
}

.popup__title {
    margin: 7px auto 5px;
    ;
    color: #2f42c2;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
}

.popup__text {
    font-size: 20px;
    margin: 10px auto;
}

.popup__img {
    width: 145px;
    height: 145px;
    margin: 31px auto;
}

.popup__nextBtn_white {
    background: #fff;
    border: 1px solid #5d3cc7;
    color: #5d3cc7;
}

.popup__nextBtn {
    margin: 8px auto 5px;
    width: 260px;
    height: 57px;
    background: #5d3cc7;
    border: 1px solid #e1e1e1;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.11;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

@media screen and (max-width: 1800px) {
    .popup__title {
        margin: 7px auto 5px;
        font-size: 18px;
    }

    .popup__text {
        font-size: 16px;
    }

    .popup__img {
        width: 113px;
        height: 113px;
        margin: 20px auto;
    }

    .popup__nextBtn {
        margin: 8px auto 5px;
        width: 203px;
        height: 45px;
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .popup__title {
        margin: 0 auto 33px;
    }

    .popup__text {
        font-size: 15px;
    }

    .popup__img {
        width: 88px;
        height: 88px;
        margin: 13px auto;
    }

    .popup__nextBtn {
        width: 159px;
        height: 35px;
        font-size: 12px;
        margin-top: 11px;
    }
}