.container {
  width: 100%;
  max-height: 100vh;
  justify-content: center;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  flex: 1 1 0;
}
.closeBtn {
  position: absolute;
  top: 94px;
  left: 25px;
  width: 13px;
  height: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../images/close_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  touch-action: manipulation;
}
.nameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  width: 100%;
  height: 65px;
  box-shadow: -7px -5px 10px 0 rgba(0, 0, 0, 0.1);
}
.name {
  margin: 15px auto 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}
.online {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #636363;
  text-align: center;
}
.messagesWrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  padding-left: 20px;
  box-sizing: border-box;
  max-width: 900px;
  flex:1;
}
.dateSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.date {
  margin: 50px 0 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  text-align: center;
}
.date::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 37%;
  background: #9c9c9c;
  top: 50%;
  left: 0;
}
.date::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 37%;
  background: #9c9c9c;
  top: 50%;
  right: 0;
}

.messagesWrapper::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
/* Handle */
.messagesWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.text {
  margin: auto;
}
.replyBar {
  margin: 0 auto 10px;
  align-self: center;
  width: 98%;
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 28px;
  min-height: 57px;
}

.input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  box-sizing: border-box;
}
.message {
  margin: 10px 20px 10px 77px;
  max-width: 100%;
  overflow-wrap: anywhere;
  width: 100%;
  outline: none;
  border: none;
}
.placeholder {
  position: absolute;
  margin: 10px 20px 10px 35px;
  max-width: 100%;
  color: #626262;
}
.btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
}
.sendBtn {
  width: 20px;
  height: 17px;
  background-image: url("../../images/chat_send_icon.svg");
  left: 18px;
}
.sendBtn_disabled{
  opacity: .6;
}
.videoBtn {
  background-image: url("../../images/chat_video_icon.svg");
  left: 19px;
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 1400px) {
  .name {
    margin: 11px auto 0;
    font-size: 20px;
    line-height: 26px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
  .messagesWrapper {
    padding: 12px;
  }
  .dateSection {
    gap: 10px;
  }
  .replyBar {
    min-height: 42px;
    font-size: 16px;
  }
  .text {
    font-size: 13px;
  }
}
@media screen and (max-width: 1200px) {
  .date{
    margin: 30px 0 14px;
    font-size: 12px;
    line-height: 1.1;
  }
}
@media screen and (max-width: 750px) {
  .messagesWrapper {
    padding: 10px;
  }
}
@media screen and (max-width: 600px) {
  .nameContainer {
    height: 130px;
  }

  .dateSection {
    gap: 20px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .avatar {
    width: 47px;
    height: 47px;
  }
  .name {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
  }
  .replyBar {
    min-height: 40px;
    width: 94%;
  }
  .videoBtn {
    left: 24px;
  }

  .message {
    margin-left: 60px;
  }
/*  for ios to prevent scale up*/
  .textArea{
    font-size: 16px;
  }
}
