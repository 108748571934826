.statusContainer {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  width: 134px;
  height: 33px;
  border-radius: 12px;
  border: 1px solid #6f6f6f;
  box-shadow: 3px 4px 16px 0 rgba(0, 0, 0, 0.07);
  color: #6f6f6f;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
.statusPoint {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #6f6f6f;
  margin-left: 2px;
}
.done {
  color: #24a148;
  border-color: #24a148;
}
.done .statusPoint {
  background-color: #24a148;
}
.inProgress {
  color: #0043ce;
  border-color: #0043ce;
}
.inProgress .statusPoint {
  background-color: #0043ce;
}
.stuck {
  color: #da1e28;
  border-color: #da1e28;
}
.stuck .statusPoint {
  background-color: #da1e28;
}
.statusOptionsList {
  display: none;
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 9px 5px;
  flex-direction: column;
  align-items: stretch;
  top: 33px;
  background-color: #fff;
  z-index: 1;
  min-width: 100%;
  gap: 9px;
  border-radius: 8px;
  box-shadow: 7px -1px 20px 0 rgba(0, 0, 0, 0.1);
}
.statusOptionsList:before {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  top: -8px;
  left: 45%;
  background-image: url("../../images/arrow_triangle_up_white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.statusOptionsList_visible {
  display: flex;
}
.statusOptionsItem {
  display: flex;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
}
.statusOptionsList .done {
  background-color: #24a148;
}
.statusOptionsList .inProgress {
  background-color: #0043ce;
}
.statusOptionsList .stuck {
  background-color: #da1e28;
}
.statusOptionsList .notYetAddressed {
  background-color: #6f6f6f;
}
@media screen and (max-width: 1300px) {
  .status {
    width: 95px;
    height: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .statusOptionsItem {
    padding: 4px 0;
  }
}
@media screen and (max-width: 1024px) {
  .status {
    width: 100px;
    height: 25px;
  }
  .statusOptions {
    font-size: 14px;
  }
}
@media screen and (max-width: 750px) {
  .status {
    width: 80px;
    height: 22px;
    font-size: 12px;
  }
  .statusOptionsList {
    min-width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .statusOptions {
    font-size: 16px;
  }
}
