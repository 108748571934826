.userSection {
    width: 95%;
    column-gap: 12px;
    position: relative;
    /*border-bottom: 1px solid #6f6f6f;*/
}
.user {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding-bottom: 10px;
}
.user__label {
    margin: 0;
    display: inline;
    font-size: 1em;
    color: #6f6f6f;
}
.user__text {
    display: inline;
    margin: 0 5px 0 0;
    font-weight: 700;
    font-size: 17px;
    color: #00214e;
}
@media screen and (max-width: 1300px) {
    .user {
        gap: 8px;
        padding-bottom: 8px;
    }
    .user__label{
        font-size: 14px;
    }
    .user__text {
        font-size: 14px;
    }
}
@media screen and (max-width: 850px) and (min-width: 600px) {
    .user {
        gap: 4px;
    }

    .user__text {
        font-size: 13px;
    }
}