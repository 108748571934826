.users {
  width: 100%;
  height: 100%;
}
.usersTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.usersHeader {
  background-color: #a9d4ff;
  width: 100%;
  padding: 20px;
  margin: 0 0 10px;
  color: #2f42c2;
  box-sizing: border-box;
}
.addButtonContainer{
  margin-left: 20px;
  align-self: flex-end;
  position: relative;
}
.usersInput {
  width: 97%;
  padding: 10px 20px;
  margin: 14px;
  box-sizing: border-box;
  font: inherit;
  font-size: 18px;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  line-height: 1;
}
.usersContent {
  width: 100%;
}
.usersListHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 140px;
  color: #00214e;
  font-size: 20px;
  width: 97%;
  margin: 0 auto;
  gap: 10px;
}
.usersList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 46vh;
  overflow-y: scroll;
}
.usersListCell {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.user {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 140px;
  gap: 10px;
  color: #2c2b2b;
  font-size: 18px;
  width: 97%;
  margin: 0 auto;
}
.user_mobile {
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}
.user_colored {
  background-color: #cee7ff;
}
.btn {
  outline: none;
  border: none;
  border-radius: 4px;
  height: 35px;
  width: 105px;
  font-family: inherit;
  color: #fff;
  background-color: #0044c9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.btn_type_actions:before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-image: url("../../images/settings_icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.btn_type_actions:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 10px;
  top: 10px;
  background-image: url("../../images/arrow_left_white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  rotate: -90deg;
}
.actions {
  margin: 0;
  list-style-type: none;
  display: none;
  flex-direction: column;
  background-color: #fff;
  color: #2c2b2b;
  padding: 10px 0;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 4;
}
.actions_visible {
  display: flex;
}
.actionsOption {
  letter-spacing: 0.1ex;
  cursor: pointer;
  width: 100%;
  padding: 3px 10px 3px 3px;
  box-sizing: border-box;
  text-align: right;
  position: relative;
  text-decoration: none;
}
.actionsOption::after {
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  background: url("../../images/arrow_left_gray.svg") no-repeat;
  background-size: contain;
  top: 9px;
  left: 8px;
}
.actionsOption:hover {
  background-color: #cee7ff;
  opacity: 0.8;
}
.usersTotal {
  width: 97%;
  margin: 14px auto;
}
.noUsers {
  width: 97%;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .usersList {
    max-height: 37vh;
  }
  .usersHeader {
    padding: 14px 10px;
    font-size: 22px;
  }
  .addButtonContainer{
    margin-left: 14px;
  }
  .usersInput {
    padding: 8px 15px;
    margin: 10px;
    font-size: 15px;
  }
  .usersList {
    max-height: 47vh;
  }
  .usersListHeader {
    grid-template-columns: 130px 1fr 1fr 1fr 1fr;
    font-size: 17px;
  }
  .usersListTitle {
    margin: 8px 0;
  }
  .user {
    grid-template-columns: 130px 1fr 1fr 1fr 1fr;
    font-size: 15px;
  }
  .userName {
    margin: 8px 10px;
    font-size: 16px;
    color: #000;
  }
  .userNameLabel {
    color: #626262;
    font-size: 16px;
    margin-right: 3px;
  }
  .btn {
    width: 112px;
    height: 30px;
  }
  .btn_type_actions:before {
    width: 10px;
    height: 10px;
    right: 12px;
    top: 10px;
  }
  .btn_type_actions:after {
    width: 10px;
    height: 10px;
    left: 10px;
    top: 10px;
    background-image: url("../../images/arrow_left_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    rotate: -90deg;
  }
  .usersTotal {
    font-size: 15px;
    margin: 8px auto;
  }
}
@media screen and (max-width: 800px) {
  .usersInput {
    margin: 14px 5px;
  }
  .usersListHeader {
    display: none;
  }
  .usersList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 52vh;
  }
  .noUsers {
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .usersTable{
    margin: 0 4px;
  }
  .usersList{
    max-height: unset;
    overflow-y: unset;
  }
}
