@import "./Calendar.css";
html{
  height: 100%;
}
body {
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.layout {
  background: #fbfaff;
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  color: #000000;
  margin: 0 auto;
  /*height: 100vh;*/
  height: 100%;
  min-height: 100%;
  position: relative;
  background: url("../images/background.jpg") top 20px right no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-position-y: 45px;
  overflow-y: auto;
}
.modals {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.hover:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
/*.lock-scroll {*/
/*  max-height: 100vh;*/
/*  overflow: hidden;*/
/*}*/
.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.scroll_thin::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #0044c9;
opacity: .5}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a19f9f;
}
.scroll_rounded::-webkit-scrollbar-thumb {
  border-radius: 4px;
}
.button {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  max-width: 290px;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.button_white {
  background: #fff;
  border: 1px solid rgba(57, 23, 169, 0.81);
  color: rgba(57, 23, 169, 0.81);
}
input,
button,
textarea {
  font-family: inherit;
}
.error {
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
}
.select__value-container--has-value .select__multi-value{
  background-color: #e1f2e6;
}
.select__group .select__group-heading{
  color: #006cc9;
  font-size: 94%;
  font-weight: 600;
}
.input{
  font: inherit;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  line-height: 1;
}
.title {
  margin: 0 auto 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #00214e;
}
.text{
  font-size: 18px;
}
.static{
  position: static;
  background: transparent;
}
@media screen and (max-width: 1300px) {
  .title {
    font-size: 20px;
    margin: 0 auto 16px;
  }
  .text{
    font-size: 17px;
  }

}

@media screen and (max-width: 1024px) {
  .error {
    font-size: 14px;
  }
  .text{
    font-size: 16px;
  }

}
@media screen and (max-width: 700px) {
  .title {
    font-size: 17px;
  }
}
