.video {
  display: block;
  width: 220px;
  height: 129px;
}
.playBtn {
  cursor: pointer;
  border: none;
  border-radius: 100%;
  outline: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 42%;
  left: 40%;
  display: block;
  background: rgba(0, 0, 0, 0.6) url("../../images/play_icon.svg") center
    no-repeat;
}
.isPlaying{
    opacity: 0;
}
.isPlaying:hover {
    background-image: url("../../images/pause_icon.svg") ;
    opacity: .8;
}
@media screen and (max-width: 1400px) {
  .video {
    width: 188px;
    height: 109px;
  }
}
@media screen and (max-width: 800px) {
  .video {
    width: 260px;
    height: 151px;
  }
}
@media screen and (max-width: 700px) {
  .video {
    width: 252px;
    height: 151px;
  }
}
