.container {
  width: 100%;
  display: flex;
}
.center{}

.btn {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  font-family: inherit;
  font-size: 16px;
  line-height: 1px;
  color: #6f6f6f;
  position: relative;
  cursor: pointer;
}
.btn_type_filter:after {
  content: "";
  width: 12px;
  height: 12px;
  background-image: url("../../images/arrow_left_gray.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 13px;
  transform: rotate(-90deg);
}
.filterOptions {
  position: absolute;
  top: 45px;
  left: 163px;
  list-style-type: none;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  border-radius: 8px;
  padding: 18px 25px;
  z-index: 3;
  color: #00214e;
  font-size: 18px;
}
.center .filterOptions{
  left: 0
}
.filterOption {
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}
.filterOption_type_status {
  display: grid;
  grid-template-columns: 21px 1fr;
  max-height: 24px;
  overflow: hidden;
  row-gap: 0;
}
.filterOption_type_status_open {
  max-height: unset;
}

/*noinspection CssUnknownProperty*/
.radio {
  accent-color: #5d3cc7cf;
}
.radio_type_status {
  width: 12px;
}
.statusOptions {
  grid-column: 1/-1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #2c2b2b;
}
.statusOption {
  margin: 4px 10px 4px 0;
}
.dateFilterContainer input[type="text"] {
  border: 1px solid rgba(93, 60, 199, 0.81);
  margin-right: 1px;
}
.dateFilterContainer {
  display: flex;
  align-items: center;
  background: rgba(93, 60, 199, 0.81);
  border: 1px solid rgba(93, 60, 199, 0.81);
  border-radius: 5px;
}
.filterBtn {
  outline: none;
  border: none;
  color: #fff;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
}
@media screen and (max-width: 1300px) {
  .btn_type_filter:after {
    width: 10px;
    height: 10px;
  }
  .filterIcon {
    width: 16px;
    height: 17px;
  }
  .filterOptions {
    top: 40px;
    padding: 14px 20px;
    font-size: 17px;
  }
  .statusOptions {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .btn {
    font-size: 14px;
  }
  .filterOptions {
    top: 32px;
    padding: 10px 14px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .btn {
    font-size: 16px;
    width: 85px;
  }
  .filterOptions {
    top: 41px;
    padding: 14px 20px;
    font-size: 18px;
  }
}
