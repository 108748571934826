.section {
  width: 96%;
  position: relative;
  direction: rtl;
  padding: 50px 0 20px;
  box-sizing: border-box;
}
.content {
  width: 95%;
  margin: 0 auto;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.input {
  min-width: 280px;
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 90px;
  background-image: url("../../images/search_icon.svg");
  background-repeat: no-repeat;
  background-position: bottom 12px right 27px;
  box-sizing: border-box;
  padding: 0 45px 0 10px;
  font-family: inherit;
  font-size: 20px;
}
.videoInput {
  display: none;
}
.themes {
  margin: 24px 0 33px;
  display: flex;
  gap: 32px;
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  overflow-x: scroll;
  width: fit-content;
  max-width: 100%;
  min-height: 50px;
  padding: 0 0 8px;
}
.themes::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
/* Handle */
.themes::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.themesLi {
  cursor: pointer;
  background: #8a74d8;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 152px;
  height: 46px;
}
.themeLi_active {
  background-color: #121759;
}
.btn {
  align-self: flex-end;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1;
  color: #121759;
}
.plus {
  color: #33a3ff;
  font-size: 14px;
  line-height: 1;
  margin-left: 9px;
}
.videos {
  padding: 0;
  margin: 20px 0 0;
  list-style-type: none;
  display: grid;
  max-width: 1184px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-template-rows: repeat(auto-fit, auto);
  gap: 16px;
  justify-items: center;
}
.video {
  position: relative;
  width: fit-content;
}
@media screen and (max-width: 1200px) {
  .title {
    margin: 34px auto 36px;
    font-size: 32px;
    line-height: 42px;
  }
  .input {
    min-width: 236px;
    min-height: 33px;
    background-position: bottom 8px right 27px;
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
  }
  .plus {
    font-size: 13px;
  }
  .videos {
    grid-template-columns: repeat(auto-fit, minmax(188px, 1fr));
  }
  .themesLi {
    min-width: 140px;
    height: 40px;
    font-size: 16px;
  }
}

@media screen and (max-width: 800px) {
  .videos {
    grid-template-columns: repeat(auto-fit, minmax(252px, 1fr));
    gap: 8px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding: 0;
  }
  .content {
    width: calc(100% - 28px);
  }
  .themes {
    gap: 20px;
    font-size: 16px;
  }
  .themesLi {
    min-width: 132px;
  }
}
