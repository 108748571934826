.messageContainer {
  width: 100%;
  display: flex;
  gap: 40px;
  direction: ltr;
  align-items: end;
}
.ownMessageContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  align-items: end;
}
.message {
  box-sizing: border-box;
  padding: 15px 20px 5px;
  flex-shrink: 1;
  width: 270px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
  text-align: right;
  color: #ffffff;
  position: relative;
  background-color: #6386f9;
  border-radius: 9px;
  direction: rtl;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.1)
}
.message::before {
  position: absolute;
  display: block;
  content: "";
  background-image: url("../../images/answer_bg_tail.png");
  background-repeat: no-repeat;
  left: -15px;
  bottom: 0;
  width: 27px;
  height: 23px;
}
.ownMessage {
  text-align: right;
  color: #000;
  background-color: #fff;
}
.ownMessage::before {
  position: absolute;
  display: block;
  content: "";
  background-image: url("../../images/own_message_bg_tail.png");
  background-repeat: no-repeat;
  right: -15px;
  bottom: 0;
  width: 27px;
  height: 23px;
}
.avatar {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: -14px;
}
.text {
  margin: 0;
}
  .textPart{
    display: block;
  }
.time {
  margin: 4px 0 0;
  font-size: 11px;
  text-align: left;
}
.time_ownMessage {
  width: fit-content;
}

@media screen and (max-width: 1200px) {
  .message,
  .ownMessage {
    font-size: 15px;
    width: 222px;
    padding: 10px 15px 5px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
  .messageContainer {
    gap: 15px;
  }
  .ownMessageContainer {
    gap: 15px;
  }
  .message {
    padding: 10px 15px 5px 30px;
    width: 200px;
    font-size: 14px;
    line-height: 16px;
  }
  .ownMessage {
    padding: 10px 15px 5px 30px;
    width: 200px;
    font-size: 14px;
    line-height: 16px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .avatar {
    width: 32px;
    height: 32px;
  }
  .message {
    padding: 15px 12px 5px 17px;
    font-size: 16px;
    line-height: 19px;
  }
  .messageContainer,
  .ownMessageContainer {
    gap: 20px;
  }
}
