.section {
  grid-template-columns: 1fr;
  grid-template-rows: 45px 45px 1fr 150px;
  display: grid;
  width: 95%;
  height: 100vh;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
}
.header {
  width: 100%;
  height: 46px;
  display: grid;
  grid-template-columns: 1fr 100px 162px;
  column-gap: 24px;
  align-items: center;
  position: relative;
}
.title {
  font-size: 20px;
  color: #000;
  margin: 0 5px 0 0;
}
.inputContainer {
  position: relative;
}
.inputContainer:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 6px;
  top: 10px;
  background-image: url("../../images/search_icon_gray.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.input {
  width: 162px;
  height: 33px;
  padding-right: 30px;
  color: #6f6f6f;
  border-radius: 8px;
  border: 1px solid #6f6f6f;
  box-sizing: border-box;
}
.tableHeader {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  justify-items: center;
  align-items: center;
  border-radius: 12px 12px 0 0;
  background: rgba(93, 60, 199, 0.81);
}
.table {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  border: 1px solid #dbd6ef;
  overflow-y: auto;
}

.tableRow {
  box-sizing: border-box;
  height: 55px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 15px;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  border: 1px solid #dbd6ef;
  border-top: none;
  cursor: pointer;
}
.tableCell {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.totalList {
  list-style-type: none;
  padding: 0;
  margin: 9px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 150px;
  gap: 21px;
}
.totalCard {
  border-radius: 12px;
  border: 1px solid #dbd6ef;
  background: #fff;
  box-shadow: 3px 4px 16px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 150px;
  font-size: 60px;
  font-weight: 600;
}
.totalCard:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: clip;
}
.totalCard_type_done {
  color: #24a148;
}
.totalCard_type_done:after {
  content: "בקשות שסגרתי היום";
  border-bottom: 2px solid #24a148;
}
.totalCard_type_inProgress {
  color: #0043ce;
}
.totalCard_type_inProgress:after {
  content: "בקשות בטיפול אצלי";
  border-bottom: 2px solid #0043ce;
}
.totalCard_type_notAddressed {
  color: #6f6f6f;
}
.totalCard_type_notAddressed:after {
  content: "ממתינות לטיפול שלי";
  border-bottom: 2px solid #6f6f6f;
}
.totalNumber {
  margin: 0;
}
.noDocuments {
  margin: 14px;
}
/*noinspection CssUnknownProperty*/
.radio {
  accent-color: #5d3cc7cf;
}
.statusOptions {
  grid-column: 1/-1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #2c2b2b;
}
.dateFilterContainer input[type="text"] {
  border: 1px solid rgba(93, 60, 199, 0.81);
  margin-right: 1px;
}
.dateFilterContainer {
  display: flex;
  align-items: center;
  background: rgba(93, 60, 199, 0.81);
  border: 1px solid rgba(93, 60, 199, 0.81);
  border-radius: 5px;
}
.filterBtn {
  outline: none;
  border: none;
  color: #fff;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
}
.preview {
  cursor: pointer;
  background-image: url("../../images/preview_icon.svg");
  border: none;
  outline: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media screen and (max-width: 1300px) {
  .section {
    grid-template-rows: 40px 40px 1fr 100px;
  }
  .header {
    height: 100%;
  }
  .title {
    font-size: 18px;
  }
  .input {
    height: 30px;
  }
  .tableHeader {
    font-size: 17px;
  }
  .tableRow {
    height: 50px;
    font-size: 14px;
  }
  .totalList {
    height: 90%;
    gap: 25px;
  }
  .totalCard {
    height: 100%;
    font-size: 40px;
    font-weight: 400;
  }
  .totalCard:after {
    top: 3px;
    right: 8px;
    font-size: 15px;
    font-weight: 400;
  }
  .statusOptions {
    font-size: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .noDocuments {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    grid-template-rows: 32px 32px 1fr 70px;
  }
  .title {
    font-size: 16px;
  }
  .tableHeader {
    font-size: 15px;
    font-weight: 400;
  }
  .tableRow {
    height: 38px;
    font-size: 13px;
  }
  .totalCard {
    font-size: 30px;
  }
  .totalCard:after {
    line-height: 1.2;
    font-size: 11px;
  }
}

@media screen and (max-width: 600px) {
  .section {
    grid-template-rows: 42px 42px 1fr 70px;
  }
  .header {
    grid-template-columns: 1fr 1fr;
  }
  .input {
    font-size: 16px;
  }
  .tableCell_hide {
    display: none;
  }
  .tableHeader {
    grid-template-columns: repeat(3, 1fr);
    font-size: 15px;
    font-weight: 400;
  }
  .tableRow {
    grid-template-columns: repeat(3, 1fr);
    font-size: 13px;
  }
  .title {
    display: none;
  }
}
