.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  direction: rtl;
  width: 95%;
  max-width: 1400px;
  position: relative;
}
.title {
  margin: 24px auto;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.userInfo{
  width: 90%;
  display: grid;
  grid-template-columns: 70% 30%;
  border-bottom : 1px solid #6f6f6f;
  padding: 0 8px;
  margin-bottom: 25px;
}
.form{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px 20px;
}
.formField{
  width: 45%;
}
.formField_type_wide{
  width: 93%;
}
.checked{
  border: 1px solid  #24a148;
  border-radius: 4px;
}
.checked .css-13cymwt-control{
  background-color: rgba(36, 161, 72, 1);
}
.reportList {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: grid;
  column-gap: 10%;
  row-gap: 14px;
  grid-template-columns: repeat(2, 304px);
}
.options{
  display: flex;
}
.option{
  display: flex;
}
.text{
  color: #626262;
  margin: 0;
}
.variants{
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
}
.variant{
  margin-top: 5px;
  padding: 4px 10px;
  background-color: #fff;
  border-radius: 4px;
}
.note{
  color: #00214e;
  font-size: 12px;
  min-height: 12px;
  margin: 0 0 3px;
  flex-basis: 92%
}
.note_red{
  color: #da1e28;
}
.submitBtn{
}
.submitBtn_disabled{
  background-color: #dbd4f4;
}
.textarea{
  min-height: 38px;
  font-size: inherit;
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 2px 10px;
}
.width_100{
  width: 100%;
}
@media screen and (max-width: 1400px) {
  .section {
    padding: 11px 0 40px;
  }
  .reportList {
    width: 75%;
    grid-template-columns: repeat(2, 1fr);
    font-size: 18px;
  }
  .formField{
    font-size: 16px;
  }
  .submitBtn{
    height: 48px;
    font-size: 17px;
  }
}
@media screen and (max-width: 1024px) {
  .reportList {
    width: 80%;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .section {
    padding: 10px 0 40px;
    width: 315px;
  }
  .formField{
    width: 100%;
  }
  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .reportList {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    margin-bottom: 30px;
  }
}
